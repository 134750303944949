import React, { Component } from 'react';
import styles from './Header.module.scss';
import logo from './logo.png';
import { HashLink as Link } from 'react-router-hash-link';
import { slide as Menu } from "react-burger-menu";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class Header extends Component {
    render() {
        library.add(faBars);
        return (
            <div className={styles.header}>
                <div className={styles.body} >
                    <div className={styles.logo} >
                        <Link to="/#top">
                            <img src={logo} alt="Platinum Precision Engineering Logo" />
                        </Link>
                    </div>
                    <div className={styles.menu} >
                        {this.drawMenuItems()}
                    </div>
                    <div className={styles.rmenu}>
                        <Menu width={200} right customBurgerIcon={<FontAwesomeIcon icon="bars" size="1x" />}>
                            {this.drawMenuItems()}
                        </Menu>
                    </div>
                </div>
            </div>
        );
    }

    private drawMenuItems() {
        return (
            <div>
                <Link to="/#about">Overview</Link>
                <Link to="/#quality">Quality</Link>
                <Link to="/#equipment">Equipment</Link>
                <Link to="/gallery">Gallery</Link>
                <Link to="/contact">Contact</Link>
            </div>
        );
    }
}

export default Header;
