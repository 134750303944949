import React, { Component } from 'react';
import qualityIso from './quality_iso.jpg';
import styles from './Landing.module.scss';
const Fade = require('react-reveal/Fade');

export class Landing extends Component {
    render() {
        return (
            <div>
                <div id='top' className={styles.landing} />
                <Fade>{this.overview()}</Fade>
                {this.quality()}
                {this.equipment()}
            </div>
        );
    }

    overview(): JSX.Element {
        return (
            <div id='about' className={styles.aboutUs}>
                <div className="container">
                    <p>Platinum Precision Engineering is a market leader in the supply chain to both National and International OEM’s within the Aerospace sector, key to Platinum’s success is customer focus driven by continuous investment in highly skilled people, state of the art equipment and effective production strategies.</p>
                </div>
            </div>
        );
    }

    quality(): JSX.Element {
        return (
            <div id='quality'>
                <Fade>
                    <div className={styles.quality}>
                        <div className="container">
                            <h2>Quality</h2>
                        </div>
                    </div>
                </Fade>
                <Fade>
                    <div className={styles.qualityBody}>
                        <div className="container">
                            <div className='two-thirds'>
                                <p>Platinum Precision Engineering is certified to ISO 9001, we are currently working to the AS9100 standard and will complete certification by the end of 2021. We have recently engaged in the SC21 program to allow us to expand our customer base.</p>
                                <p>We are quality approved for Bombardier Aerospace, Thompson Aero Seating and Collins Aerospace.</p>
                            </div>
                            <div className='one-third'>
                                <img width='100%' src={qualityIso} alt="Quality certifications" />
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        )
    }

    equipment(): JSX.Element {
        return (
            <div id='equipment'>
                <Fade>
                    <div className={styles.equipment}>
                        <div className="container">
                            <h2>Equipment</h2>
                        </div>
                    </div>
                </Fade>
                <Fade>
                    <div className={styles.equipmentBody}>
                        <div className="container">
                            <p>Platinum Precision Engineering has invested in 3, 4 & 5 axis machining technology, our multi pallet machines allow uninterrupted production day and night ensuring delivery of customer components on time and on budget. Our dedicated team are on hand to program components using the latest CAM software including Mastercam and Hypermill enabling quick turnaround of products for our loyal customers.</p>
                        </div>
                    </div>
                </Fade>
            </div>
        )
    }
}

export default Landing;
