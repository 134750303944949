import React, { Component } from 'react';
import styles from './Contact.module.scss';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';

type State = {
    lat: number,
    lng: number,
    zoom: number,
}

export class Contact extends Component<{}, State> {
    state = {
        lat: 54.2313341,
        lng: -5.8933700,
        zoom: 15,
    }

    render(): JSX.Element {
        const position: LatLngExpression = [this.state.lat, this.state.lng]
        return (
            <div className={styles.contact}>
                <div className={styles.contactTitle}>
                    <div className='container'>
                        <h2>Contact Us</h2>
                    </div>
                </div>
                <div className={styles.contactBody}>
                    <div className='container'>
                        <div>
                            <p>You can contact us by email here:&nbsp; <a href="mailto:info@platinum-ni.com">info@platinum-ni.com</a></p>
                            <p>Or by phone at <a href="tel:02843724821">028 4372 4821</a> or <a href="tel:07531255955">075 3125 5955</a></p>
                        </div>
                    </div>
                </div>
                <Map center={position} zoom={this.state.zoom}>
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MyMarker position={position}>
                        <Popup>
                            <strong>Platinum Precision Engineering</strong> <br />
                            15 Carrigs Road,<br />
                            Newcastle, <br />
                            BT33 0JZ
                        </Popup>
                    </MyMarker>
                </Map>
            </div>
        );
    }
}

const MyMarker = (props: any) => {

    const initMarker = (ref: any) => {
        if (ref) {
            ref.leafletElement.openPopup()
        }
    }

    return <Marker ref={initMarker} {...props} />
}