import React, { Component } from 'react';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
import styles from './Gallery.module.scss';

class GalleryPage extends Component {
    constructor() {
        super();
        this.state = { currentImage: 0 };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
    }

    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }
    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    openLightbox(event, obj) {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }
    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    render() {
        return (
            <div className={styles.main}>
                <div className={styles.galleryTitle}>
                    <div className="container">
                        <h2>Gallery</h2>
                    </div>
                </div>
                <div className="container">
                    <Gallery photos={this.getImages('/thumbs')} onClick={this.openLightbox} />
                    <Lightbox images={this.getImages('')}
                        onClose={this.closeLightbox}
                        onClickPrev={this.gotoPrevious}
                        onClickNext={this.gotoNext}
                        currentImage={this.state.currentImage}
                        isOpen={this.state.lightboxIsOpen}
                    />
                </div>
            </div>
        );
    }

    getImages(prefix) {
        const images = [];
        for(let i = 1; i < 25; i++) {
            const imageNo = i < 10 ? '0' + i : i;
            images.push({ src: '/images' + prefix + '/image' + imageNo + '.jpg', width: 4, height: 3 });
        }
        return images;
    }
}

export default GalleryPage;