import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Landing from "./pages/Landing/Landing";
// import { Footer } from "./components/Footer/Footer";
// import Privacy from "./pages/Privacy/Privacy";
import Gallery from "./pages/Gallery/Gallery";
import { Contact } from "./pages/Contact/Contact";

function App() {
  return (
    <Router>
      <Header />
      <div>
        <Route exact path="/" component={Landing} />
        <Route path="/gallery" component={Gallery} />
        <Route path="/contact" component={Contact} />
        {/* <Route path="/privacy" component={Privacy} /> */}
      </div>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
